import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'

const NftCard = ({
  nft: {
    image,
    title,
    text,
    linkUrl,
    webLinkText,
    webLinkUrl,
    twitterLinkText,
    twitterLinkUrl,
    discordLinkText,
    discordLinkUrl,
    blogLinkText,
    blogLinkUrl,
  },
}) => (
  <div className="mb-4">
    <a href={linkUrl} target="_blank" rel="noreferrer" className="group block">
      <GatsbyImage image={getImage(image)} alt={title} className="mb-2" />
      <h3 className="mb-2 group-hover:underline font-bold">{title}</h3>
    </a>
    <p>{text}</p>
    <ul>
      {webLinkText && (
        <li>
          <a
            href={webLinkUrl}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-gray"
          >
            {webLinkText}
          </a>
        </li>
      )}
      {twitterLinkText && (
        <li>
          <a
            href={twitterLinkUrl}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-gray"
          >
            {twitterLinkText}
          </a>
        </li>
      )}
      {discordLinkText && (
        <li>
          <a
            href={discordLinkUrl}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-gray"
          >
            {discordLinkText}
          </a>
        </li>
      )}
      {blogLinkText && (
        <li>
          <a
            href={blogLinkUrl}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-gray"
          >
            {blogLinkText}
          </a>
        </li>
      )}
    </ul>
  </div>
)

export default NftCard
