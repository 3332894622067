import React from 'react'

const BoredApeOutfitDownloadButton = ({ outfit, buttonCta }) => (
  <a
    className="pink-button flex justify-center mb-6"
    download={`${outfit.name}.png`}
    // href={outfit.outfit.localFile.publicURL}
  >
    {buttonCta}
  </a>
)

export { BoredApeOutfitDownloadButton }
