import React from 'react'
import { format } from 'date-fns'

const EventCard = ({
  event: {
    buttonCta,
    name,
    start,
    end,
    url,
    description: { description },
  },
}) => {
  const fromDateFormated = format(new Date(start), 'eeee d/M ha')
  const toDateFormated = format(new Date(end), 'ha')

  return (
    <div className="flex flex-col my-4 justify-between">
      <h3 className="mb-2 group-hover:underline font-bold">
        {fromDateFormated} - {toDateFormated}
      </h3>
      <p>{description}</p>
      <a
        className="pink-button flex-grow-0 justify-center mb-6"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {buttonCta}
      </a>
    </div>
  )
}

export { EventCard }
