import { Link } from 'gatsby'
import React from 'react'

const NavButtonListing = ({ navButtons = [] }) => (
  <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4 py-6">
    {navButtons
      .filter(({ disabled }) => !disabled)
      .map(
        (
          {
            buttonCta,
            toPath,
            color,
            external,
            externalUrl,
            externalTargetBlank,
            disabled,
          },
          index
        ) => (
          <li className="flex items-center" key={index}>
            {external ? (
              <a
                className={color === 'pink' ? 'pink-button' : 'white-button'}
                href={externalUrl}
                target={externalTargetBlank ? '_blank' : '_self'}
                rel="noreferrer"
              >
                {buttonCta}
              </a>
            ) : (
              <Link
                to={toPath}
                className={color === 'pink' ? 'pink-button' : 'white-button'}
              >
                {buttonCta}
              </Link>
            )}
          </li>
        )
      )}
    {/* <li className="flex">
        <button onClick={scrollAndHighlightNewsletter} className="white-button">
          Newsletter
        </button>
      </li> */}
  </ul>
)

export { NavButtonListing }
