import React from 'react'
import OutfitCard from './OutfitCard'

const BoredApeOutfitListing = ({ outfits = [] }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-6">
    {outfits.map((outfitObject, index) => (
      <OutfitCard outfit={outfitObject} key={index} />
    ))}
  </div>
)

export { BoredApeOutfitListing }
