import * as React from 'react'

import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BoredApeOutfitListing } from './BoredApeOutfitListing'
import Separator from './Separator'
import { EmbeddedImage } from './EmbeddedImage'
import { BoredApeOutfitDownloadButton } from './BoredApeOutfitDownloadButton'
import { EventListing } from './EventListing'
import { CannabisInfusedProductCard } from './CannabisInfusedProductCard'
import { NftListing } from './NftListing'
import { NavButtonListing } from './NavButtonListing'
import { NavCardListing } from './NavCardListing'
import Newsletter from './Newsletter'

const richTextReferences = {}

const options = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="py-6 font-display text-6xl uppercase">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h3 className="font-bold text-2xl">{children}</h3>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="font-bold ">{children}</h3>
    ),
    [BLOCKS.HR]: () => <Separator className="my-5" />,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const imageData = richTextReferences[node.data.target.sys.id]
      const image = getImage(imageData.image)
      return <GatsbyImage image={image} alt={imageData.alt} />
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const entryData = richTextReferences[node.data.target.sys.id] || {}
      const {
        __typename,
        boredApeOutfits,
        boredApeOutfit,
        buttonCta,
        image,
        alignment,
        altText,
        name,
        events,
        ratio,
        productImage,
        graphImage,
        description,
        color,
        nfts,
        navButtons,
        navCards,
        inputPlaceholder,
        cta,
        errorMessage,
        successMessage,
      } = entryData

      switch (__typename) {
        case 'ContentfulEmbeddedImage':
          return (
            <EmbeddedImage image={image} alt={altText} alignment={alignment} />
          )
        case 'ContentfulNewsletterSignup':
          return (
            <Newsletter
              name={name}
              cta={cta}
              inputPlaceholder={inputPlaceholder}
              errorMessage={errorMessage}
              successMessage={successMessage}
            />
          )
        case 'ContentfulNavButtonListing':
          return <NavButtonListing navButtons={navButtons} />
        case 'ContentfulNavCardListing':
          return <NavCardListing navCards={navCards} />
        case 'ContentfulBoredApeOutfitListing':
          return <BoredApeOutfitListing outfits={boredApeOutfits} />
        case 'ContentfulEventListing':
          return <EventListing name={name} events={events} />
        case 'ContentfulNftListing':
          return <NftListing name={name} nfts={nfts} />
        case 'ContentfulCannabisInfusedProduct':
          return (
            <CannabisInfusedProductCard
              name={name}
              ratio={ratio}
              alignment={alignment}
              productImage={productImage}
              graphImage={graphImage}
              description={description}
              color={color}
            />
          )
        case 'ContentfulBoredApeOutfitDownloadButton':
          return (
            <BoredApeOutfitDownloadButton
              outfit={boredApeOutfit}
              buttonCta={buttonCta}
            />
          )

        default:
          break
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      // render the EMBEDDED_ASSET as you need
      const entryData = richTextReferences[node.data.target.sys.id] || {}
      const { __typename, image, alignment, altText } = entryData
      switch (__typename) {
        case 'ContentfulEmbeddedImage':
          return (
            <EmbeddedImage image={image} alt={altText} alignment={alignment} />
          )
        default:
          break
      }
    },
  },
}

const RichText = ({ content: { raw, references } }) => {
  if (references) {
    references.map(
      reference => (richTextReferences[reference.contentful_id] = reference)
    )
  }
  return <div>{documentToReactComponents(JSON.parse(raw), options)}</div>
}

export default RichText
