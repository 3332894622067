import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const EmbeddedImage = ({ image, alt, alignment }) => (
  <span
    className={classNames('mx-auto', 'mb-6', 'block', 'text-center', {
      'md:inline-block md:ml-6 md:float-right md:max-w-1/2':
        alignment === 'right',
      'md:inline-block md:mr-6 md:float-left md:max-w-1/2':
        alignment === 'left',
    })}
  >
    <GatsbyImage as={'span'} image={getImage(image)} alt={alt} />
  </span>
)

export { EmbeddedImage }
