import { Link } from 'gatsby'
import * as React from 'react'
import classNames from 'classnames'
import arrow from '../images/arrow.svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const getCard = (title, image, isComingSoon, callToAction) => (
  <div
    className={classNames('border', 'mb-5', 'flex', 'flex-col', {
      'opacity-50': isComingSoon,
      group: !isComingSoon,
      transition: !isComingSoon,
    })}
  >
    <div className="flex flex-row flex-grow flex-nowrap h-36 max-h-36">
      <div className="p-3 flex-1 group-hover:bg-white group-hover:text-black transition-colors">
        <span
          className={classNames(
            'font-display',
            'text-4xl',
            'leading-10 uppercase',
            {
              'text-gray': isComingSoon,
            }
          )}
        >
          {title}
        </span>
      </div>
      <div className={classNames('flex-1', { 'bg-gray': !image })}>
        {image && (
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            objectFit="cover"
            className="h-full w-full object-cover"
          />
        )}
      </div>
    </div>
    <div className="bg-white text-black py-1 px-3 h-10 flex items-center justify-between">
      <span className="truncate">{callToAction}</span>
      <img src={arrow} alt="Read more" className="" />
    </div>
  </div>
)

const Card = ({
  title,
  image,
  isComingSoon,
  toPath,
  callToAction,
  external,
  externalUrl,
  externalTargetBlank,
}) => {
  if (isComingSoon) {
    return getCard(title, image, isComingSoon, callToAction)
  }
  if (external) {
    return (
      <a
        href={externalUrl}
        target={externalTargetBlank ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {getCard(title, image, isComingSoon, callToAction)}
      </a>
    )
  }
  return (
    <Link to={toPath}>{getCard(title, image, isComingSoon, callToAction)}</Link>
  )
}
export default Card
