import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

const CannabisInfusedProductCard = ({
  name,
  ratio,
  alignment,
  productImage,
  graphImage,
  description,
  color,
}) => {
  return (
    <div
      className={classNames(
        'flex',
        'flex-col-reverse',
        'md:flex-row',
        'my-4',
        'justify-between',
        'items-center',
        'text-center',
        'md:text-left',
        { 'md:flex-row-reverse': alignment === 'right' }
      )}
    >
      <div className="flex-1 pr-4">
        <h3 className="pb-6 font-display text-6xl uppercase">
          <span style={{ color }}>{ratio}</span> - {name}
        </h3>
        <p>{description.description}</p>
        <GatsbyImage image={getImage(graphImage)} alt={name} />
      </div>
      <div className="flex-1 py-4">
        <GatsbyImage image={getImage(productImage)} alt={name} />
      </div>
    </div>
  )
}

export { CannabisInfusedProductCard }
