import React from 'react'
import Card from './Card'

const NavCardListing = ({ navCards = [] }) => (
  <div className="grid md:grid-cols-2 md:gap-4 py-6">
    {navCards
      .filter(({ disabled }) => !disabled)
      .map(
        (
          {
            cardTitle,
            image,
            comingSoon,
            toPath,
            cardCta,
            external,
            externalUrl,
            externalTargetBlank,
          },
          index
        ) => (
          <Card
            key={index}
            title={cardTitle}
            image={image}
            toPath={toPath}
            isComingSoon={comingSoon}
            callToAction={cardCta}
            external={external}
            externalUrl={externalUrl}
            externalTargetBlank={externalTargetBlank}
          />
        )
      )}
  </div>
)

export { NavCardListing }
