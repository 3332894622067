import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'
import * as React from 'react'

const OutfitCard = ({
  outfit: { name, fromDate, toDate, apeWithOutfit, outfit },
}) => {
  const fromDateFormated = format(new Date(fromDate), 'MMMM dd')
  const toDateFormated = format(new Date(toDate), 'MMMM dd')

  return (
    <div className="mb-4">
      <GatsbyImage
        image={getImage(apeWithOutfit)}
        alt={name}
        className="mb-2"
      />
      <h3 className="mb-2 group-hover:underline font-bold">
        {fromDateFormated} - {toDateFormated}
      </h3>
      <ul>
        <li>
          <a
            download={`${name}.png`}
            // href={outfit.localFile.publicURL}
            className="underline hover:text-gray"
          >
            Download outfit
          </a>
        </li>
      </ul>
    </div>
  )
}

export default OutfitCard
