import React from 'react'
import NftCard from './NftCard'

const NftListing = ({ nfts = [], name }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-6">
    {nfts.map((nft, index) => (
      <NftCard nft={nft} key={index} />
    ))}
  </div>
)

export { NftListing }
