import * as React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useState } from 'react'

const Newsletter = ({
  name,
  inputPlaceholder,
  cta,
  errorMessage,
  successMessage,
}) => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { result } = await addToMailchimp(email)

    if (result === 'success') {
      setError(false)
      setSuccess(true)
    }
    if (result === 'error') {
      setSuccess(false)
      setError(true)
    }
  }

  return (
    <div className="py-6 " id="newsletter">
      <h2 className="font-display text-4xl leading-10 uppercase mb-3">
        {name}
      </h2>
      <form onSubmit={handleSubmit} className="flex flex-col md:flex-row">
        <input
          placeholder={inputPlaceholder}
          onChange={handleChange}
          type="email"
          className="flex-1 no-underline text-black bg-white p-4 mt-3 md:mr-4 focus:outline-white"
          style={{ outlineStyle: 'solid' }}
          value={email}
        />
        {error && (
          <div className="flex p-4 text-center justify-center text-red">
            {errorMessage}
          </div>
        )}
        {success ? (
          <div className="flex p-4 text-center justify-center text-green">
            {successMessage}
          </div>
        ) : (
          <input
            type="submit"
            disabled={success || !email.length}
            value={cta}
            className="flex-1 text-center cursor-pointer border bg-black mt-3 p-4 hover:bg-white hover:text-black transition-colors active:bg-gray disabled:pointer-events-none"
          />
        )}
      </form>
    </div>
  )
}

export default Newsletter
