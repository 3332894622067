import React from 'react'
import { EventCard } from './EventCard'
import Separator from './Separator'

const EventListing = ({ events = [], name }) => (
  <div className="grid grid-cols-1 mx-auto text-center max-w-sm gap-4 py-6">
    <h1 className="py-6 font-display text-6xl uppercase">{name}</h1>
    {events.map((event, index) => (
      <div key={index}>
        <EventCard event={event} />
        {index + 1 < events.length && <Separator />}
      </div>
    ))}
  </div>
)

export { EventListing }
